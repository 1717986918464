.landingBg {
  background: rgb(11, 25, 62);
  background: linear-gradient(
    90deg,
    rgba(11, 25, 62, 1) 0%,
    rgba(39, 21, 37, 1) 80%,
    rgba(56, 19, 21, 1) 100%
  );
  min-height: calc(100vh - 0px);
  font-family: var(--primaryfont);
  padding: 20px 35px;
  overflow: hidden;
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

:root {
  --primarycolor: #b99e71;
  --navMobile: #231629;
  --navlink: #958262;
  --footerTextColr: #999b9f;
  --primaryfont: "apercuregular";
}

.bgbody {
  font-family: var(--primaryfont);
  overflow: hidden;
  width: 100%;
}

.landingBg input,
.landingBg select,
.landingBg textarea {
  color: #fff !important;
}

.landingBg input:hover ~ fieldset,
.landingBg select:hover ~ fieldset,
.landingBg textarea:hover ~ fieldset,
.landingBg .MuiOutlinedInput-root:hover ~ fieldset {
  border-color: rgb(147 148 149 / 62%) !important;
}

.landingBg select option {
  color: #222 !important;
}

.uerPopup .popupFeild input:hover ~ fieldset,
.uerPopup .popupFeild select:hover ~ fieldset,
.uerPopup .popupFeild textarea:hover ~ fieldset,
.userclndr fieldset:hover,
.userclndr .MuiInputAdornment-outlined:hover fieldset {
  border-color: rgb(147 148 149 / 62%) !important;
}

.uerPopup input,
.uerPopup select,
.uerPopup textarea {
  color: #fff !important;
}

.landingBg .transparentTable .MuiPaper-root {
  background-color: transparent;
}

.landingBg .transparentTable table thead th {
  background-color: rgb(83 81 112);
  background-image: linear-gradient(
    270deg,
    rgb(83 81 112) 0%,
    rgb(83 81 112) 100%
  );
  color: #fff;
}

.landingBg .transparentTable table thead th:first-child,
.landingBg .transparentTable table thead th:last-child {
  box-shadow: inset -8px 0 0 rgb(83 81 112);
}

.landingBg .transparentTable h6 {
  color: #fff;
}

.landingBg .transparentTable tbody tr td {
  color: #fff;
}

.userclndr .MuiPickersPopper-paper {
  color: #fff;
  background-color: #535170;
  box-shadow: none;
}

.userclndr .MuiDayPicker-header > span {
  color: #fff;
}

.landingBg .MuiPagination-ul button.Mui-disabled {
  opacity: 0.5;
}

.landingBg .MuiPagination-ul button {
  color: #fff;
}

.z2 {
  position: relative;
  z-index: 2;
}

html {
  touch-action: manipulation;
  scroll-behavior: smooth;
}

html,
body {
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}

img {
  width: 100%;
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.NavflexBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deeper-morph {
  position: absolute;
  left: auto;
  right: -127%;
  top: 0;
  transform: translate(1%, -87%);
  height: 300px;
}

.deeper-morph svg {
  float: right;
  height: 100%;
}

.logo {
  width: 200px;
}

.logo img {
  width: 165px;
}

.navbar {
  align-items: center;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 0 !important;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  justify-content: right;
}

.sticky {
  position: fixed !important;
  top: 0px;
  width: 100%;
  backdrop-filter: blur(8px);
  background-color: rgb(8 20 28 / 86%);
  z-index: 999;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 10px 15px;
}

/* Menu */

.hamburger {
  background: transparent;
  border: none;
  cursor: pointer;
  display: none !important;
  outline: none;
  height: 20px;
  position: relative;
  width: 20px;
}

.menu-active .hamburger {
  left: 170px;
}

.hamburger-line {
  background: #e5e5e5;
  height: 2px;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamburger:hover .hamburger-line {
  background: #fff;
}

.hamburger-line-top {
  top: 3px;
}

.menu-active .hamburger-line-top {
  top: 50%;
  transform: rotate(45deg) translatey(-50%);
}

.hamburger-line-middle {
  top: 50%;
  transform: translatey(-50%);
}

.menu-active .hamburger-line-middle {
  left: 50%;
  opacity: 0;
  width: 0;
}

.hamburger-line-bottom {
  bottom: 3px;
}

.menu-active .hamburger-line-bottom {
  bottom: 50%;
  transform: rotate(-45deg) translatey(50%);
}

.navbar .push-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 0.25s ease-in;
  justify-content: right;
  /* align-items: baseline; */
  align-items: center;
  width: 100%;
}

.flexBox {
  display: flex;
  flex-wrap: wrap;
}

.loginBtn {
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid #ffde00;
  border-radius: 5px;
  text-align: center;
  padding: 3px 17px;
  margin-left: 10px;
}

.loginBtn:hover {
  color: #ffde00;
}

.nav-menu .menu-item a {
  color: var(--navlink);
  display: block;
  line-height: 30px;
  margin: 0px 10px;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: 2px;
  position: relative;
  text-transform: uppercase;
  margin-top: 8px;
}

.nav-menu .menu-item a::after {
  content: "/";
  position: absolute;
  right: -14px;
}
.nav-menu .menu-item.no-slash a::after {
  content: ""; /* No slash for elements with the no-slash class */
}
.nav-menu .menu-item a:hover::after,
.nav-menu .menu-item.active a::after {
  color: var(--navlink);
}

.nav-menu .menu-item:last-child a::after {
  display: none;
}

.nav-menu .menu-item a:hover,
.nav-menu .menu-item.active a {
  color: #fff;
}

.sub-nav {
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 5px 5px;
  list-style: none;
  width: 230px;
}

.nav__link:hover + .sub-nav {
  display: block;
}

.sub-nav:hover {
  display: block;
}

.navcontainer {
  /* position: fixed; */
  top: 20px;
  /* width: calc(100% - 70px); */
  /* z-index: 3; */
}

canvas {
  display: block;
  vertical-align: bottom;
}

/* ---- tsparticles container ---- */
#tsparticles {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 0.1;
  /* z-index: 1; */
  overflow: hidden;
}

.countDetail {
  color: var(--primarycolor);
  text-transform: uppercase;
  position: absolute;
  font-size: 11px;
  letter-spacing: 2px;
  font-family: "apercu_promedium";
  z-index: 2;
  /* display: flex; */
  width: calc(100% / 3);
}

.countDetailsub {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.countDetail1 {
  top: 120px;
  left: 20px;
}

.countDetail2 {
  top: 120px;
  left: 250px;
}

.countDetail3 {
  top: 120px;
  left: 500px;
}

.countDetail4 {
  top: 260px;
  left: 20px;
}

.countDetail5 {
  top: 260px;
  left: 250px;
}

.countDetail6 {
  top: 260px;
  left: 500px;
}

.countDetail7 {
  top: 410px;
  left: 20px;
}

.countDetail8 {
  top: 410px;
  left: 250px;
}

.countDetail9 {
  top: 410px;
  left: 500px;
}

.countDetail10 {
  top: 540px;
  left: 20px;
}

.countDetail11 {
  top: 540px;
  left: 250px;
}

.countDetail12 {
  top: 540px;
  left: 500px;
}

.number {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.numbeText {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 3;
}

.numbersmall {
  font-size: 17px;
}

.bannerHomeLeftContnt {
  position: relative;
  z-index: 3;
}

.bannerfooternav {
  display: flex;
  justify-content: right;
}

.bannerFooterSub {
  /* border: 1px solid #453b44; */
  width: auto;
  width: 330px;
  /* float: right; */
  display: flex;
  margin-right: 25px;
}

.contentArea {
  height: 750px;
  width: 750px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bannerfooternav {
  display: flex;
}

.bannerSectionHome {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 130px);
  margin-top: 30px;
}

.contentglobeImg {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
}

.contentArea:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../images/globe.png") no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.3;
  z-index: 1;
}

.blobeImg {
  width: 600px;
  display: block;
  margin: auto;
}

.bannerfooternav li {
  list-style: none;
}

.bannerfooternav li a:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.bannerfooternav li {
  padding: 10px 7px;
}

.bannerfooternav li a {
  color: #6b7685;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  position: relative;
}

.bannerfooternav li a:after {
  content: "/";
  position: absolute;
  right: -10px;
  top: -1px;
}

.bannerfooternav li:last-child a:after {
  display: none;
}

.bannerfooternav li a:hover {
  color: #fff;
}

a,
a:hover {
  text-decoration: none !important;
}

.clearfix {
  display: block;
  min-height: 1px;
  width: 100%;
  clear: both;
}

.bannerFooter {
  position: fixed;
  z-index: 3;
  bottom: 0;
  /* left: 0; */
  width: 100%;
  padding: 30px 40px 35px 0px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.copyRight {
  color: #6b7685;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 330px;
}

.bannerfootersocial {
  display: flex;
}

.bannerfootersocial li {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-left: 1px solid #453b44; */
}

.bannerfootersocial li a {
  color: #93929c;
  font-size: 18px;
  line-height: 1;
}

.bannerfootersocial li a:hover {
  color: #fff;
}

.animated-number {
  display: inline-block;
  transition: transform 1.5s ease-out, opacity 0.5s ease-out;
  /* Slow return for transform, quick for opacity */
  transform: translateY(0);
  /* Initial position */
  opacity: 1;
  /* Fully visible */
}

.spanishLetter {
  font-size: 9px;
  color: #c3c3c3;
}

.slide-up {
  transform: translateY(-10px);
  /* Slide up by 20 pixels */
  opacity: 0.5;
  /* Make the number invisible while sliding up */
}

.enLetter,
.spanishLetter {
  font-size: 11px;
}

.contentgbefore {
  width: 77%;
  margin: auto;
  text-align: center;
}

.contentgbefore p {
  color: #fff;
  letter-spacing: 1px;
  font-family: "apercu_pro_light";
  font-style: italic;
  line-height: 24px;
}

.relative {
  position: relative;
  z-index: 2;
}

.contentgbefore .auther {
  color: var(--navlink);
  margin-top: 20px;
  position: relative;
  letter-spacing: 2px;
}

.contentgbefore .auther:before {
  content: "";
  position: absolute;
  left: 20px;
  height: 1px;
  width: 80px;
  background-color: var(--primarycolor);
  top: 10px;
}

.contentgbefore .auther:after {
  content: "";
  position: absolute;
  right: 20px;
  height: 1px;
  width: 80px;
  background-color: var(--primarycolor);
  top: 10px;
}

@keyframes fadeOutIn1 {
  0% {
    opacity: 0;
    /* fontBlink1 is fully visible */
    display: none;
  }

  20% {
    opacity: 1;
    /* fontBlink1 is fully visible */
    display: block;
  }

  50% {
    opacity: 0;
    /* fontBlink1 fades out quickly */
    display: none;
  }

  100% {
    opacity: 0;
    /* fontBlink1 remains hidden */
    display: none;
  }
}

@keyframes fadeOutIn2 {
  0%,
  50% {
    opacity: 0;
    /* fontBlink2 is hidden initially */
    display: none;
  }

  60%,
  85% {
    opacity: 1;
    /* fontBlink2 fades in smoothly and stays visible */
    display: block;
  }

  100% {
    opacity: 0;
    /* fontBlink2 starts to fade out quickly */
    display: block;
  }
}

.fontBlink1,
.fontBlink2 {
  animation-duration: 20s;
  /* Total cycle time for each animation */
  animation-iteration-count: infinite;
  /* Repeat infinitely */
  height: 80px;
}

.fontBlink1 {
  animation-name: fadeOutIn1;
  font-size: 19px;
}

.fontBlink2 {
  animation-name: fadeOutIn2;
  font-size: 18px;
}

.addrexxBox {
  margin-top: 45px;
}

.addrexxBoxNew li {
  list-style: disc;
  margin-left: 18px;
}
.addrexxBox p {
  line-height: 20px;
  font-size: 13px !important;
  color: #acb4c1;
  font-style: normal;
}

.signInUp.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.signInUp .modal-content {
  background: #141827;
  padding: 30px;
  max-width: 400px;
  width: 90%;
  border-radius: 9px;
  position: relative;
}

.signInUp .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
}

.closebtnModal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
}

.signInUp .tabs {
  display: flex;
  justify-content: flex-start;
}

.signInUp .tabs button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 15px;
  color: var(--navlink);
  text-transform: uppercase;
}

.signInUp .tabs .active {
  border-bottom: 2px solid var(--navlink);
}

.signInUp .tab-content {
  margin-top: 20px;
}

.dropdownBg {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.dropdownBgContainer .MuiPaper-root {
  background-color: rgb(34 23 42);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
  color: #fff;
  font-family: "apercu_pro_light" !important;
}

.dropdownBgContainer .MuiPaper-root > span,
.bgnotifctnBox .MuiPaper-elevation > span {
  background-color: rgb(34 23 42);
}

.dropdownBgContainer .MuiTypography-subtitle2 {
  color: #fff;
}

.avtarHolder.MuiButtonBase-root:before {
  background-color: transparent;
}

.bgnotifctnBox .MuiPaper-elevation {
  box-shadow: none;
  background-color: transparent;
}

.bgnotifctn {
  background-color: rgb(34 23 42);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
  color: #fff;
  font-family: "apercu_pro_light" !important;
  display: block;
}

.signInUp input {
  display: block;
  width: 100%;
  margin-bottom: 18px;
  padding: 10px 0;
  border: 0;
  background-color: transparent !important;
  /* Ensure background is transparent */
  border-bottom: 1px solid #242a41;
  color: #fff !important;
  outline: none;
  letter-spacing: 0.6px;
}

.signInUp input:-internal-autofill-selected {
  background-color: transparent !important;
}

.butonstyle1 {
  width: 100%;
  padding: 15px;
  background-color: var(--navlink);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  text-transform: uppercase;
}

.butonstyle3 {
  width: 80%;
  padding: 15px;
  background-color: var(--navlink);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  text-transform: uppercase;
}

.butonstyle3:hover {
  background-color: #40331c;
  /* Example hover color */
}

.butonstyleEmail {
  width: 100px;
  padding: 6.1px;
  background-color: var(--navlink);
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
  border-radius: 4px;
  /* margin-top: 10px; */
  text-transform: uppercase;
}

.butonstyle1.butonstyle2 {
  background-color: #121212;
}

.sharePopup .MuiPaper-root {
  background: #141827;
  /* background: linear-gradient(90deg, rgba(11, 25, 62, 1) 0%, rgba(39, 21, 37, 1) 80%, rgba(56, 19, 21, 1) 100%); */
}

.signInUp input:-webkit-autofill,
.signInUp input:-webkit-autofill:hover,
.signInUp input:-webkit-autofill:focus,
.signInUp input:-webkit-autofill:active {
  -webkit-background-clip: text;
  box-shadow: inset 0 0 20px 20px transparent;
}

.signInUp ::placeholder {
  color: #ccc;
  opacity: 1;
  /* Firefox */
  letter-spacing: 2px;
}

.signInUp input:-webkit-autofill:first-line {
  color: #fff;
}

.MobilenavMenu {
  display: none;
}

.HeaderBreadcrumbsUserTitle {
  color: #fff !important;
}

.HeaderBreadcrumbsUser ul li div a {
  color: #b1becb !important;
}

.qrcodeBox {
  width: 100%;
  max-width: 410px;
  padding: 25px;
  border-radius: 6px;
  background: rgb(11, 25, 62);
  background: linear-gradient(
    90deg,
    rgba(11, 25, 62, 1) 0%,
    rgba(39, 21, 37, 1) 80%,
    rgba(56, 19, 21, 1) 100%
  );
  margin: auto;
}

.hyphenAfter {
  position: relative;
}

.hyphenAfter:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 60px;
  margin-left: -75px;
  background-color: #fff;
  top: 12px;
}

.hyphenAfter:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 60px;
  right: 22px;
  background-color: #fff;
  top: 12px;
}

.mobileNotifctn {
  display: none;
}

.notiIcon {
  position: absolute;
  top: 9px;
  left: -11px;
}

.iconColor {
  color: var(--primarycolor);
}

.iconColorBg {
  background-color: var(--primarycolor) !important;
  color: #fff !important;
}

.atTop {
  position: fixed;
  width: 100%;
  z-index: 99;
  background: rgb(11, 25, 62);
  background: linear-gradient(
    270deg,
    rgb(93 34 37) 0%,
    rgb(83 46 73) 80%,
    rgb(15 34 85) 100%
  );
  top: 0;
  left: 0;
  height: 64px;
  padding: 0 35px;
}

.uerPopup .MuiDialog-paper {
  background-color: #22172a !important;
}

.uerPopupBorder .MuiDialog-paper {
  border: 1px solid #372643;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #372643a8 !important;
}

/* **************************************************************** */

@media (max-width: 1570px) {
  .countDetail1 {
    top: 90px;
    left: 20px;
  }

  .countDetail2 {
    top: 90px;
    left: 220px;
  }

  .countDetail3 {
    top: 90px;
    left: 420px;
  }

  .countDetail4 {
    top: 220px;
    left: 20px;
  }

  .countDetail5 {
    top: 220px;
    left: 220px;
  }

  .countDetail6 {
    top: 220px;
    left: 420px;
  }

  .countDetail7 {
    top: 330px;
    left: 20px;
  }

  .countDetail8 {
    top: 330px;
    left: 220px;
  }

  .countDetail9 {
    top: 330px;
    left: 420px;
  }

  .countDetail10 {
    top: 440px;
    left: 20px;
  }

  .countDetail11 {
    top: 440px;
    left: 220px;
  }

  .countDetail12 {
    top: 440px;
    left: 420px;
  }

  .contentArea {
    height: 620px;
    width: 620px;
  }
}

/* **************************************************************** */

@media (max-width: 1370px) {
  .countDetail1 {
    top: 120px;
    left: 20px;
  }

  .countDetail2 {
    top: 120px;
    left: 200px;
  }

  .countDetail3 {
    top: 120px;
    left: 400px;
  }

  .countDetail4 {
    top: 240px;
    left: 20px;
  }

  .countDetail5 {
    top: 240px;
    left: 200px;
  }

  .countDetail6 {
    top: 240px;
    left: 400px;
  }

  .countDetail7 {
    top: 340px;
    left: 20px;
  }

  .countDetail8 {
    top: 340px;
    left: 200px;
  }

  .countDetail9 {
    top: 340px;
    left: 400px;
  }

  .countDetail10 {
    top: 450px;
    left: 20px;
  }

  .countDetail10 {
    top: 450px;
    left: 20px;
  }

  .countDetail11 {
    top: 450px;
    left: 200px;
  }

  .countDetail12 {
    top: 450px;
    left: 400px;
  }

  .contentArea {
    height: 600px;
    width: 600px;
  }

  .number {
    font-size: 29px;
  }

  .numbeText {
    font-size: 17px;
  }
}

/* **************************************************************** */
@media (max-width: 1300px) {
  .landingBg {
    padding: 20px 20px;
  }

  /* .navcontainer {
    width: calc(100% - 40px);
  } */

  .NavMainContainer {
    left: 30px;
  }
  .bannerFooterSub {
    margin-right: 0px;
  }
}

/* **************************************************************** */

@media (max-width: 1070px) {
  .countDetail1 {
    top: 90px;
    left: 0px;
  }

  .countDetail2 {
    top: 90px;
    left: 180px;
  }

  .countDetail3 {
    top: 90px;
    left: 370px;
  }

  .countDetail4 {
    top: 200px;
    left: 0px;
  }

  .countDetail5 {
    top: 200px;
    left: 180px;
  }

  .countDetail6 {
    top: 200px;
    left: 370px;
  }

  .countDetail7 {
    top: 300px;
    left: 0px;
  }

  .countDetail8 {
    top: 300px;
    left: 180px;
  }

  .countDetail9 {
    top: 300px;
    left: 370px;
  }

  .countDetail10 {
    top: 400px;
    left: 0px;
  }

  .countDetail11 {
    top: 400px;
    left: 180px;
  }

  .countDetail12 {
    top: 400px;
    left: 370px;
  }

  .number {
    font-size: 25px;
  }

  .contentArea {
    height: 550px;
    width: 550px;
  }

  .enLetter,
  .spanishLetter {
    font-size: 10px;
  }
}

/* ************************************************************* */
@media (min-width: 992.1px) {
  .mobileLogo {
    display: none;
  }

  .MobilenavMenu {
    display: none;
  }
}

/* **************************************************************** */

@media (max-width: 992px) {
  .atTop {
    padding: 0;
    background: none;
    position: static;
  }

  .iconColor {
    color: #fff;
  }

  .iconColorBg {
    color: var(--primarycolor) !important;
    background-color: #fff !important;
  }

  .mobileNotifctn {
    display: flex;
    position: absolute;
    right: 57px;
    align-items: center;
    height: 23px;
  }

  .MobilenavMenu {
    display: block;
  }

  .notiIcon {
    top: 8px;
  }

  .NavMainContainer {
    width: 200px;
    /* height:calc(100vh - 50px); */
    overflow-y: auto;
    display: flex;
    margin-top: 0;
    top: 50px;
    left: 0;
    position: relative;
    height: 0;
    z-index: 99;
  }

  .navcontainer {
    width: 100%;
    z-index: 3;
    background: rgb(11, 25, 62);
    background: linear-gradient(
      270deg,
      rgb(15 34 85) 0%,
      rgb(83 46 73) 80%,
      rgb(93 34 37) 100%
    );
    padding: 15px 10px;
    border-radius: 4px;
    left: 16px;
    top: 16px;
  }

  .navbar {
    padding: 0 !important;
  }

  .landingBg {
    padding: 10px;
  }

  .navbar {
    justify-content: left !important;
    align-items: flex-start !important;
  }

  .MobilenavMenu {
    background-color: var(--navMobile) !important;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    padding: 20px 20px 20px !important;
    width: 250px !important;
    z-index: 9;
  }

  .MobilenavMenu li a {
    color: var(--navlink);
    letter-spacing: 2px;
    font-size: 14px;
  }

  .MobilenavMenu li a:hover {
    color: #fff !important;
  }

  .MobilenavMenu li {
    padding: 6px 15px 6px 0;
  }

  .menuCloseBtn {
    position: fixed;
    top: 14px;
    left: 33px;
    z-index: 10;
  }

  .mobileLogo {
    position: absolute;
    left: 45px;
    width: 150px;
    /* top: 8px; */
  }

  .hamburger {
    display: inline-block !important;
  }

  .nav-menu .menu-item a {
    font-size: 14px;
    margin: 4px 15px;
    line-height: 24px;
  }

  .nav-menu {
    flex-direction: column;
    opacity: 0;
    position: absolute;
    top: -20px;
    left: -30px;
    transform: translatey(-100%);
    text-align: left;
    align-items: flex-start;
    height: 100vh;
    width: 250px;
    padding-top: 70px;
    background-color: var(--navMobile);
    z-index: 5;
    padding-left: 30px;
  }

  .nav-menu .menu-item a::after {
    display: none;
  }

  .menu-active .nav-menu {
    transform: translatey(0%);
    opacity: 1;
  }

  .logo {
    display: none;
  }
}

/* **************************************************************** */

@media (max-width: 768px) {
  .navbar {
    padding: 0;
  }

  .enLetter,
  .spanishLetter {
    font-size: 9px;
  }

  .hamburger {
    display: inline-block;
  }

  .nav-menu .menu-item a {
    font-size: 14px;
    margin: 4px 15px;
    line-height: 24px;
  }

  .nav-menu {
    flex-direction: column;
    opacity: 0;
    position: absolute;
    top: -20px;
    left: -30px;
    transform: translatey(-100%);
    text-align: left;
    align-items: flex-start;
    height: 100vh;
    width: 250px;
    padding-top: 70px;
    background-color: var(--navMobile);
    z-index: 5;
    padding-left: 30px;
  }

  .nav-menu .menu-item a::after {
    display: none;
  }

  .menu-active .nav-menu {
    transform: translatey(0%);
    opacity: 1;
  }

  .logo {
    display: none;
  }

  .menu-active #menu-toggler {
    left: 16px;
    top: -1px;
    position: absolute;
    z-index: 9;
  }

  .copyright {
    font-size: 12px;
    margin-left: 8px;
    text-align: left;
  }

  .bannerSectionHome {
    padding: 20px 0;
    min-height: 100%;
  }

  .bannrDtailmainBox:before {
    top: 0;
  }

  .countDetail2 {
    left: 170px;
  }

  .countDetail3 {
    left: 350px;
  }

  .countDetail4 {
    left: 0px;
  }

  .countDetail5 {
    left: 170px;
  }

  .countDetail6 {
    left: 350px;
  }

  .countDetail7 {
    left: 0px;
  }

  .countDetail8 {
    left: 170px;
  }

  .countDetail9 {
    left: 350px;
  }

  .countDetail10 {
    left: 0px;
  }

  .countDetail11 {
    left: 170px;
  }

  .countDetail12 {
    left: 350px;
  }

  .bannerFooter {
    padding: 0px 20px 35px 0;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
  }

  .bannerfooternav li {
    padding: 6px 8px;
  }

  .bannerfooternav li a:after {
    right: -11px;
  }

  .bannerFooterSub {
    width: auto;
    margin-bottom: 15px;
  }

  .copyRight {
    margin-top: 15px;
    text-align: center;
  }
}

/* **************************************************************** */

@media (min-width: 576.1px) {
  .d-sm-none {
    display: none;
  }
}

/* **************************************************************** */

@media (max-width: 576px) {
  .numbeText {
    line-height: 2;
  }

  .qrcodeBox {
    max-width: 360px;
    padding: 3px;
  }

  .contentArea {
    height: 800px;
    max-width: 550px;
    width: 100%;
  }

  .contentArea.contentAreatext {
    height: 550px;
  }

  .contentgbefore p {
    font-size: 19px;
  }

  .contentgbefore p.fontBlink2 {
    font-size: 16px;
    line-height: 24px;
  }

  .bannerSectionHome {
    height: auto;
  }

  .bannerFooter {
    position: relative;
  }

  .bannerFooter {
    position: relative;
  }

  .bannerFooterSub {
    width: auto;
  }

  .bannerFooterSub {
    display: block;
  }

  .countDetail {
    text-align: left;
  }

  .countDetail1,
  .countDetail3,
  .countDetail5,
  .countDetail7,
  .countDetail9,
  .countDetail11 {
    left: 0px;
    margin-top: 20px;
  }

  .countDetail1 .countDetailsub,
  .countDetail3 .countDetailsub,
  .countDetail5 .countDetailsub,
  .countDetail7 .countDetailsub,
  .countDetail9 .countDetailsub,
  .countDetail11 .countDetailsub {
    text-align: left;
  }

  .countDetail2,
  .countDetail4,
  .countDetail6,
  .countDetail8,
  .countDetail10,
  .countDetail12 {
    right: 0px;
    left: auto;
    margin-top: 20px;
  }

  .countDetail2 .countDetailsub,
  .countDetail4 .countDetailsub,
  .countDetail6 .countDetailsub,
  .countDetail8 .countDetailsub,
  .countDetail10 .countDetailsub,
  .countDetail12 .countDetailsub {
    text-align: right;
  }

  .countDetail1,
  .countDetail2 {
    top: 100px;
  }

  .countDetail3,
  .countDetail4 {
    top: 200px;
  }

  .countDetail5,
  .countDetail6 {
    top: 300px;
  }

  .countDetail7,
  .countDetail8 {
    top: 400px;
  }

  .countDetail9,
  .countDetail10 {
    top: 500px;
  }

  .countDetail11,
  .countDetail12 {
    top: 600px;
  }

  .countDetail {
    width: calc(100% / 2);
  }

  .contentglobeImg {
    height: 100%;
    min-width: 300px;
    min-height: calc(100vh - 110px);
  }

  #tsparticles {
    width: 92%;
    height: 92%;
  }

  .bannerFooter {
    padding: 5px 0px;
  }

  .bannerFooterSub {
    border: none;
    justify-content: space-between;
  }

  .bannerFooterSub {
    width: 100%;
  }

  .bannerfootersocial {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    border: 1px solid #2c2638;
    width: 135px;
    margin: 10px auto;
  }

  .bannerfootersocial li {
    width: 45px;
    height: 45px;
  }

  .bannerfooternav {
    justify-content: center;
    flex-wrap: wrap;
  }

  /* .bannerfooternav li a {
    font-size: 8px;
  } */

  .contentgbefore .auther:before,
  .contentgbefore .auther:after {
    width: 40px;
  }

  @keyframes fadeOutIn1 {
    0% {
      opacity: 0;
      /* fontBlink1 is fully visible */
      display: none;
    }

    30% {
      opacity: 1;
      /* fontBlink1 is fully visible */
      display: block;
    }

    50% {
      opacity: 0;
      /* fontBlink1 fades out quickly */
      display: none;
    }

    100% {
      opacity: 0;
      /* fontBlink1 remains hidden */
      display: none;
    }
  }

  @keyframes fadeOutIn2 {
    0%,
    50% {
      opacity: 0;
      /* fontBlink2 is hidden initially */
      display: none;
    }

    60%,
    85% {
      opacity: 1;
      /* fontBlink2 fades in smoothly and stays visible */
      display: block;
    }

    100% {
      opacity: 0;
      /* fontBlink2 starts to fade out quickly */
      display: block;
    }
  }

  .contentgbefore .auther {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0px auto;
    width: 100%;
    font-size: 14px;
  }

  .contentgbefore p.fontBlink1 {
    position: absolute;
    top: -90px;
    line-height: 20px;
    font-size: 13px;
  }

  .contentgbefore p.fontBlink2 {
    position: absolute;
    top: -90px;
    line-height: 19px;
    font-size: 12px;
  }

  .addrexxBox {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 35px auto;
  }

  .addrexxBox p {
    font-size: 12px !important;
  }
}

/* **************************************************************** */
